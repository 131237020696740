<template>
  <div class="col main_attributes_wrapper_container">
    <!-- Attributes List  -->

    <!-- //? Large Screens  -->
    <server-table
      class="hidden-sm-only hidden-xs-only"
      :count="$store.getters['attributes/attributesCount']"
      :DataItems="$store.getters['attributes/get_attributes']"
      @Refresh="Refresh"
      @export="Export"
      @Export_Bulk="Export_Bulk"
      @addBulk="add_bulk_dialog = true"
      @reset="filter = {}"
      :filter="filter"
      import_url="attributes/import"
      :loading="$store.getters['attributes/usersLoad']"
      :title="`${localization('Attributes')}`"
      :fileLoading="isLoading"
      :allow_bulk_process="true"
      admin_permission="admin_users"
      edit_permission="add_users"
    >
      <template #columns>
        <el-table-column
          prop="id"
          label="#"
          sortable
          width="100"
        ></el-table-column>
        <el-table-column
          prop="title"
          :label="`${localization('Title')}`"
          min-width="150"
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ scope.row.title }}
            </div>
          </template></el-table-column
        >
        <el-table-column
          prop="pattern"
          sortable
          :label="`${localization('Pattern')}`"
          min-width="170"
        >
        </el-table-column>
        <el-table-column
          prop="type"
          sortable
          :label="`${localization('Type')}`"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          prop="Language"
          :label="`${localization('Language')}`"
          sortable
          width="120"
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ language(languages, scope.row.language) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="age_group_id"
          sortable
          :label="`${localization('Age')}`"
          min-width="130"
        >
          <template slot-scope="scope">
            {{ getAgeGroup(ageList, scope.row.age_group_id) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="removed"
          sortable
          :label="`${localization('State')}`"
          width="120"
        >
          <template slot-scope="scope">
            <l-button
              outline
              round
              size="sm"
              :type="scope.row.removed == 0 ? 'success' : 'danger'"
              style="font-size: 11px; margin: 1px; padding: 2px 7px"
            >
              {{ scope.row.removed == 0 ? "active" : "inactive" }} </l-button
            >&nbsp;
          </template>
        </el-table-column>

        <el-table-column prop="" label="_" width="100">
          <template slot-scope="scope">
            <div class="td-actions">
              <!-- <router-link
                v-if="scope.row.parent_id == 0"
                :to="`/attribute/${scope.row.id}`"
                v-tooltip.top-center="'Show Attribute'"
                class="btn btn-info btn-link btn-xs"
              >
                <i class="fa fa-eye"></i>
              </router-link> -->
              <a
                v-tooltip.top-center="`${localization('Edit attribute')}`"
                class="btn btn-warning btn-link btn-xs"
                @click="open_attribute_dialog(2, scope.row)"
              >
                <i class="fa fa-edit"></i>
              </a>
              <a
                v-tooltip.top-center="
                  scope.row.removed == 0
                    ? `${localization('Delete attribute')}`
                    : `${localization('Active attribute')}`
                "
                @click="remove(scope.row.id, scope.row.removed)"
                :class="
                  scope.row.removed == 0
                    ? `btn btn-danger btn-link btn-xs`
                    : `btn btn-success btn-link btn-xs`
                "
              >
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                <i v-else class="fa fa-check"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #filter>
        <el-row :gutter="10">
          <el-col :md="8">
            <el-select
              v-model="filter.pattern"
              clearable
              :placeholder="`${localization('Pattern')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(item, index) in pattern"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :md="8"
            ><el-select
              v-model="filter.age_group_id"
              :placeholder="`${localization('Age')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(age, i) in ageList"
                :key="i"
                :label="age.age"
                :value="age.id"
              ></el-option> </el-select
          ></el-col>
          <el-col :md="8"
            ><el-select
              v-model="filter.language"
              :placeholder="`${localization('Language')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(language, i) in languages"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option> </el-select
          ></el-col>
        </el-row>
      </template>
      <template #action>
        <div type="info" class="ml-2" @click="open_attribute_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add attribute") }}
        </div>
      </template>
    </server-table>

    <!-- //? Medium & Small Screens  -->
    <server-table
      class="hidden-md-only hidden-lg-only hidden-xl-only"
      :count="$store.getters['attributes/attributesCount']"
      :DataItems="$store.getters['attributes/get_attributes']"
      @Refresh="Refresh"
      @export="Export"
      @Export_Bulk="Export_Bulk"
      @addBulk="add_bulk_dialog = true"
      @reset="filter = {}"
      :filter="filter"
      import_url="attributes/import"
      :loading="$store.getters['attributes/usersLoad']"
      :title="`${localization('Attributes')}`"
      :fileLoading="isLoading"
      :allow_bulk_process="true"
      admin_permission="admin_users"
      edit_permission="add_users"
    >
      <template #columns>
        <el-table-column
          prop="id"
          label="#"
          sortable
          width="70"
        ></el-table-column>
        <el-table-column
          prop="title"
          :label="`${localization('Title')}`"
          min-width="150"
        >
          <template slot-scope="scope">
            <div
              class="
                d-flex
                flex-column flex-sm-row
                justify-content-sm-between
                align-items-sm-center
              "
            >
              {{ scope.row.title }}

              <div class="d-flex justify-content-between">
                <a
                  v-tooltip.top-center="`${localization('Show attribute')}`"
                  @click="open_show_attribute_dialog(scope.row)"
                  class="btn btn-info btn-link btn-xs"
                >
                  <i class="fa fa-eye"></i>
                </a>
                <a
                  v-tooltip.top-center="`${localization('Edit attribute')}`"
                  class="btn btn-warning btn-link btn-xs"
                  @click="open_attribute_dialog(2, scope.row)"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  v-tooltip.top-center="
                    scope.row.removed == 0
                      ? `${localization('Delete attribute')}`
                      : `${localization('Active attribute')}`
                  "
                  @click="remove(scope.row.id, scope.row.removed)"
                  :class="
                    scope.row.removed == 0
                      ? `btn btn-danger btn-link btn-xs`
                      : `btn btn-success btn-link btn-xs`
                  "
                >
                  <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                  <i v-else class="fa fa-check"></i>
                </a>
              </div>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #filter>
        <el-row :gutter="10">
          <el-col :md="8">
            <el-select
              v-model="filter.pattern"
              clearable
              :placeholder="`${localization('Pattern')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(item, index) in pattern"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :md="8"
            ><el-select
              v-model="filter.age_group_id"
              :placeholder="`${localization('Age')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(age, i) in ageList"
                :key="i"
                :label="age.age"
                :value="age.id"
              ></el-option> </el-select
          ></el-col>
          <el-col :md="8"
            ><el-select
              v-model="filter.languages"
              :placeholder="`${localization('Language')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(language, i) in languages"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option> </el-select
          ></el-col>
        </el-row>
      </template>
      <template #action>
        <div type="info" class="ml-2" @click="open_attribute_dialog(1, {})">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add attribute") }}
        </div>
      </template>
    </server-table>

    <!-- //? Start Add or Edit Attribute  -->
    <el-dialog
      :title="
        type == 1
          ? `${localization('Add attribute')}`
          : `${localization('Edit attribute')}`
      "
      width="60%"
      :visible.sync="attribute_dialog"
      top="4vh"
      class="add_attributes_dialog_wrapper"
    >
      <el-form
        :model="attribute"
        style="width: 100%"
        ref="addAttributeForm"
        :rules="addAttributeRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="24"
            ><el-form-item
              :label="`${localization('Title')}`"
              style="width: 100%"
              prop="title"
            >
              <el-input
                type="text"
                v-model="attribute.title"
                :placeholder="`${localization('Please enter title')}`"
                style="width: 100%"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :md="12"
            ><el-form-item
              :label="`${localization('Pattern')}`"
              prop="pattern"
              class="w-100"
            >
              <el-select
                v-model="attribute.pattern"
                :placeholder="`${localization('Please select a pattern')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(pattern, i) in pattern"
                  :key="i"
                  :label="pattern.label"
                  :value="pattern.label"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :md="12"
            ><el-form-item
              :label="`${localization('Type')}`"
              prop="type"
              class="w-100 mr-lg-3"
            >
              <el-select
                v-model="attribute.type"
                :placeholder="`${localization('Please select type')}`"
                style="width: 100%"
              >
                <el-option value="Use First">{{
                  localization("Use First")
                }}</el-option>
                <el-option value="Avoid">{{ localization("Avoid") }}</el-option>
                <el-option value="As Needed">{{
                  localization("As Needed")
                }}</el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item
              :label="`${localization('Language')}`"
              style="width: 100%"
              prop="language"
            >
              <el-select
                v-model="attribute.language"
                :placeholder="`${localization('Please select language')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(language, i) in languages"
                  :key="i"
                  :label="language.title"
                  :value="language.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item
              :label="`${localization('Age Group')}`"
              class="mr-2"
              prop="age_group_id"
            >
              <el-select
                v-model="attribute.age_group_id"
                :placeholder="`${localization('Please select Age group')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in ageList"
                  :key="index"
                  :label="item.age"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          dialog_footer
        "
      >
        <el-button
          class="text-center text-capitalize save_button delete_button"
          @click="attribute_dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          class="text-center text-capitalize save_button"
          type=""
          @click="save"
          >{{ localization("Save") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- //? End Add or Edit Attribute  -->

    <!-- //? Add bulk Dialog  -->
    <el-dialog
      :title="`${localization('Add Bulk')}`"
      :visible.sync="add_bulk_dialog"
      top="4vh"
      width="60%"
      :close-on-click-modal="false"
      class="add_attributes_dialog_wrapper"
    >
      <el-form
        :model="addBulkModel"
        ref="bulkRuleForm"
        :rules="bulkRules"
        v-loading="isLoading"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :lg="12"
            ><el-form-item
              :label="`${localization('Language')}`"
              prop="language"
            >
              <el-select
                v-model="addBulkModel.language"
                :placeholder="`${localization('Please select language')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in languages"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :lg="12"
            ><el-form-item
              :label="`${localization('Age')}`"
              prop="age_group_id"
            >
              <el-select
                v-model="addBulkModel.age_group_id"
                :placeholder="`${localization('Please select Age group')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in ageList"
                  :key="index"
                  :label="item.age"
                  :value="item.id"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :lg="12"
            ><el-form-item :label="`${localization('Pattern')}`" prop="pattern">
              <el-select
                v-model="addBulkModel.pattern"
                :placeholder="`${localization('Please select a pattern')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in pattern"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :lg="12"
            ><el-form-item :label="`${localization('Type')}`" prop="type">
              <el-select
                v-model="addBulkModel.type"
                :placeholder="`${localization('Please select type')}`"
                style="width: 100%"
              >
                <el-option value="Use First">{{
                  localization("Use First")
                }}</el-option>
                <el-option value="Avoid">{{ localization("Avoid") }}</el-option>
                <el-option value="As Needed">{{
                  localization("As Needed")
                }}</el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" class="row-bg" justify="end">
          <el-col :lg="12">
            <el-form-item class="w-100">
              <el-upload
                class="w-100"
                style="
                  display: grid;
                  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
                  grid-gap: 1rem;
                "
                :auto-upload="false"
                :file-list="fileList"
                :on-change="handleUploadChange"
                :on-success="Refresh"
                accept=".xlsx"
                :before-upload="beforeUpload"
                :action="import_src_bulk"
                :data="{
                  language: addBulkModel.language,
                  type: addBulkModel.type,
                  pattern: addBulkModel.pattern,
                  age_group_id: addBulkModel.age_group_id
                }"
                :disabled="
                  !addBulkModel.language ||
                    !addBulkModel.pattern ||
                    !addBulkModel.type ||
                    !addBulkModel.age_group_id
                "
                ref="uploadAttribute"
              >
                <el-button
                  @click="validateFileUpload"
                  slot="trigger"
                  class="w-100"
                  type="primary"
                  >{{ localization("Select file") }}</el-button
                >
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer dialog_footer">
        <el-button
          class="text-center text-capitalize save_button delete_button"
          @click="add_bulk_dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          type=""
          class="text-center text-capitalize save_button "
          @click="submitUpload"
          :disabled="isLoading"
          >{{ localization("Submit") }}</el-button
        >
      </div>
    </el-dialog>

    <!-- //? Show attributes dialog on medium & small screens  -->
    <el-dialog
      :title="`${localization('Attribute')}`"
      :visible.sync="show_attribute_dialog"
      top="4vh"
      width="70%"
      class="add_attributes_dialog_wrapper"
    >
      <Info :items="shown_attribute" />
      <div
        class="
          dialog-footer
          dialog_footer
        "
      >
        <el-button
          type=""
          class="text-center text-capitalize save_button delete_button"
          @click="show_attribute_dialog = false"
        >
          {{ localization("Cancel") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import Info from "../../components/Cards/Info";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";

export default {
  name: "index",
  components: { ServerTable, Info },
  data() {
    return {
      attribute_dialog: false,
      attribute: {},
      type: null,
      filter: {},
      languages: [],
      show_attribute_dialog: false, // small and medium screens dialog
      shown_attribute: {},
      addAttributeRules: {
        title: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        type: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        age_group_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        pattern: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ],
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ]
      },
      // for filter
      pattern: [
        { label: "Confluence" },
        { label: "Precision" },
        { label: "Sequence" },
        { label: "Technical Reasoning" }
      ],
      ageList: [],

      localSort: { col: "id", order: "DESC" },
      // preserve sorting concept to local component

      // For Bulk functionality
      add_bulk_dialog: false,
      addBulkModel: {},
      isLoading: false,
      fileList: [],

      bulkRules: {
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        pattern: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        age_group_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        type: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    import_src_bulk() {
      return `${process.env.VUE_APP_BASE_URL}/attributes-language/import`;
    }
  },
  mounted() {
    this.$store.dispatch("attributes/GetTeamStatements");
  },
  methods: {
    Refresh(query) {
      // stop loading after importing bulk
      this.isLoading = false;
      // close add bulk dialog if opened
      if (this.add_bulk_dialog) this.add_bulk_dialog = false;
      // empty file list ofr bulk upload
      this.fileList = [];
      // dispatch action for getting Attributes list
      this.$store
        .dispatch("attributes/GetAttributes", { query: query })
        .then(_ => {
          // get languages for table
          this.languages = this.$store.getters["attributes/languages"];
          // get age groups for table
          this.ageList = this.$store.getters["attributes/age_groups"];
        });
    },
    // format language for table show
    language(languages, id) {
      let language = languages.filter(el => el.id == id)[0];
      if (language) {
        return language.title;
      }
      return "-";
    },
    getAgeGroup(ageList, id) {
      let age = ageList.filter(item => item.id == id)[0];
      if (age) {
        return age.age;
      }
    },
    open_attribute_dialog(type, value) {
      this.attribute = { ...value };
      this.attribute_dialog = true;
      this.type = type;
    },
    remove(id, removed) {
      Swal.fire({
        title: "",
        text:
          removed == 0
            ? `Are you sure you want to delete this attribute?`
            : "Are you sure you want to Active this attribute?",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: removed == 0 ? "delete" : "confirm",
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("attributes/DeleteAttribute", id).then(_ => {
            this.Refresh(null);
            Swal.fire({
              title: removed == 0 ? `` : "",
              text:
                removed == 0
                  ? "Attribute has been deleted."
                  : "Attribute has been activated.",
              icon: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false
            });
          });
        }
      });
    },

    save() {
      this.$refs["addAttributeForm"].validate(valid => {
        if (valid) {
          // add new attribute
          if (this.type == 1) {
            this.$store
              .dispatch("attributes/AddAttribute", { query: this.attribute })
              .then(_ => {
                this.Refresh({});
                this.attribute_dialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Attribute has been added.",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          } else {
            this.$store
              .dispatch("attributes/EditAttribute", {
                id: this.attribute.id,
                query: this.attribute
              })
              .then(_ => {
                this.Refresh({
                  limit: 4,
                  offset: 0,
                  order_by: this.localSort.col,
                  order_direction: this.localSort.order
                });
                this.attribute_dialog = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Attribute has been updated.",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              });
          }
        } else {
          return false;
        }
      });
    },
    beforeUpload(file) {
      // start loading before uploading
      this.isLoading = true;
    },
    // normal export
    Export(query) {
      this.$store.dispatch("attributes/Export", { query: query });
    },
    // export bulk
    Export_Bulk() {
      this.$store.dispatch("attributes/ExportBulk");
    },
    handleUploadChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    validateFileUpload() {
      this.$refs["bulkRuleForm"].validate(valid => {
        if (valid) {
          return true;
        } else {
          return false;
        }
      });
    },
    submitUpload() {
      this.$refs["bulkRuleForm"].validate(valid => {
        if (valid) {
          this.$refs.uploadAttribute.submit();
          Swal.fire({
            title: "",
            icon: "success",
            text: "Bulk has been added.",
            type: "success",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false
          });
        } else {
          return false;
        }
      });
    },
    // dialog for medium & small screens
    open_show_attribute_dialog(item) {
      this.shown_attribute = Object.entries(item)
        .map(([key, value]) => {
          return { key, value };
        })
        .filter(
          item =>
            item.key !== "parent_id" &&
            item.key !== "created_at" &&
            item.key !== "removed" &&
            item.key !== "isEnabled" &&
            item.key !== "updated_at"
        );
      this.show_attribute_dialog = true;
    }
  }
};
</script>

<style scoped lang="scss">
.container--fluid {
  min-height: 80vh !important;
}

::v-deep .el-table .cell {
  white-space: normal;
  word-break: break-word;
}

.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;

  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
  .save_button {
    background-color: #23ccef;
    border-color: #23ccef;
    min-width: 150px;
    color: #fff;
    @media (min-width: 320px) and (max-width: 767px) {
      min-width: 124px;
    }
    &.delete_button {
      background-color: #f56c6c;
      border-color: #f56c6c;
    }
  }
}
</style>

<style lang="scss">
.main_attributes_wrapper_container {
  .add_attributes_dialog_wrapper {
    .el-dialog {
      @media (min-width: 320px) and (max-width: 767px) {
        width: 90% !important;
      }
    }
  }
}
</style>
